import React from 'react';
import ReactDOM from 'react-dom';
import util from "../util/util";
import ISO6391 from 'iso-639-1';
import RenderField from '../components/RenderField'
import { withRouter } from 'react-router-dom';
class Footer extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            language: ((localStorage.getItem('language') !== 'null' && localStorage.getItem('language') !== 'null') ?
                localStorage.getItem('language') : 'sv-se'),
            portalAdd: this.props.portalAdd === "undefined" ? false : this.props.portalAdd
        }
    }

    componentWillMount() {
        if (localStorage.getItem('language') === null) {
            localStorage.setItem('language', 'sv-se')
        }
    }

    handleChange = (event) => {
        this.setState({ language: event.target.value });
        localStorage.setItem('language', event.target.value);
        window.location.reload();
    }

    render() {
        var language = util.getLanguage();
        let fields = null;
        if (typeof this.props.fields === "undefined") {
            fields = util.getDocument('component_' + language, 'footer')
        }
        else {
            fields = this.props.fields;
        }
        const globalSettings = util.getDocument('settings', 'global')
        const languages = globalSettings.languages;
        var footerDiv = <footer>
            <div>
                <RenderField source={fields.addressFooter} />
            </div>
            <div>
                <RenderField source={fields.firstlinkbox} />
            </div>
            <div>
                <RenderField source={fields.secondlinkbox} />
            </div>
            <div>
                <RenderField source={fields.thirdlinkbox} />
            </div>
            <div className="language">
                <form>

                    <select value={this.state.language} onChange={this.handleChange} defaultValue={this.state.language}>
                        {
                            languages.map(
                                (language, index) => {
                                    let languagePrefix = language.split('-');
                                    var selectedAttribute = {};
                                    if (language === this.state.language) {
                                        selectedAttribute["selected"] = "selected";
                                    }
                                    return <option {...selectedAttribute} key={index} value={language}>{ISO6391.getName(languagePrefix[0])}</option>
                                }
                            )}
                    </select>
                </form>
            </div>
        </footer>
        return footerDiv;
    }
}
export default withRouter(Footer);