import React, { Component, Fragment } from 'react'
import { BrowserRouter as Router, Route, Switch, Redirect } from 'react-router-dom'
import Navbar from "./components/Navbar";
import Footer from "./components/Footer";
import data from './data.json'
import { slugify, slugifyNonKebab } from './util/url'
import Meta from './components/Meta'
import HomePageContent from './views/HomePageContent';
import Tjansten from "./views/Tjansten";
import NoMatch from './views/NoMatch';
import "./stylesheets/styles.scss";
import CaseList from './views/CasesList';
import Case from './views/Case';
import utils from "./util/util";
import { withRouter } from 'react-router-dom';
import util from './util/util';
import Nyheter from "./views/Nyheter";
import Partner from "./views/Partner";
import Support from "./views/Support";
import Omoss from "./views/Omoss";
import Anvandarvillkor from "./views/Anvandarvillkor";
import Cookies from "./views/Cookies";
import Avtalsmallar from "./views/Avtalsmallar";
import Utvecklare from "./views/Utvecklare";
import NyheterList from './views/NyheterList';
import AvtalsmallarList from "./views/AvtalsmallerList";
import ScrollToTop from "./util/ScrollToTop";
const RouteWithMeta = ({ component: Component, ...props }) => {
  return (

    <Route
      {...props}
      render={routeProps => (
        <Fragment>
          <Meta {...props} />
          <div className="container">
            <Navbar />
            <Component {...routeProps} {...props} />
          </div>
          <Footer portalAdd={true} />
        </Fragment>
      )}
    />
  )
}

class App extends Component {
  state = {
    data,
    language: util.getLanguage()
  }

  render() {
    console.log(this.state.language)
    var language = this.state.language;
    const globalSettings = utils.getDocument('settings', 'global');
    const languages = globalSettings.languages;
    const {
      siteTitle,
      siteUrl,
      siteDescription,
      socialMediaCard,
      headerScripts
    } = globalSettings
    const casesList = utils.getDocuments('cases_' + language).filter(
      individualcase => individualcase.status !== 'Draft'
    )
    const nyheterList = utils.getDocuments('nyheter_' + language).filter(
      individualcase => individualcase.status !== 'Draft'
    )
    const avtalsmallar = utils.getDocuments('avtalsmallar' + language).filter(
      individualcase => individualcase.status !== 'Draft'
    )
    return (
      <>
        <Router basename="/sv">
          <ScrollToTop>

            <Switch>
              <RouteWithMeta
                path='/'
                exact
                component={HomePageContent}
                description={siteDescription}
                fields={utils.getDocument('pages_' + language, 'home')}
                globalSettings={globalSettings}
                pageCssClass={'page-index'}
                language={language}
              />
              <RouteWithMeta
                path='/Tjansten'
                exact
                component={Tjansten}
                description={siteDescription}
                fields={utils.getDocument('pages_' + language, 'tjansten')}
                globalSettings={globalSettings}
                pageCssClass={'page-tjansten'}
                language={language}
              />
              <RouteWithMeta
                path='/cases'
                exact
                component={CaseList}
                description={siteDescription}
                fields={utils.getDocument('pages_' + language, 'cases')}
                globalSettings={globalSettings}
                casesList={casesList}
                pageCssClass={'page-cases'}
                language={language}
              />
              <RouteWithMeta
                path='/nyheter'
                exact
                component={NyheterList}
                description={siteDescription}
                fields={utils.getDocument('pages_' + language, 'nyheter')}
                globalSettings={globalSettings}
                nyheterList={nyheterList}
                pageCssClass={'page-nyheter'}
              />
              <RouteWithMeta
                path='/partners'
                exact
                component={Partner}
                description={siteDescription}
                fields={utils.getDocument('pages_' + language, 'Partners')}
                globalSettings={globalSettings}
                casesList={casesList}
                pageCssClass={'page-partners'}
              />
              <RouteWithMeta
                path='/utvecklare'
                exact
                component={Utvecklare}
                description={siteDescription}
                fields={utils.getDocument('pages_' + language, 'utvecklare')}
                globalSettings={globalSettings}
                casesList={casesList}
                pageCssClass={'page-utvecklare'}
              />
              <RouteWithMeta
                path='/support'
                exact
                component={Support}
                description={siteDescription}
                fields={utils.getDocument('pages_' + language, 'Support')}
                globalSettings={globalSettings}
                casesList={casesList}
                pageCssClass={'page-support'}
              />
              <RouteWithMeta
                path='/om-oss'
                exact
                component={Omoss}
                description={siteDescription}
                fields={utils.getDocument('pages_' + language, 'omoss')}
                globalSettings={globalSettings}
                casesList={casesList}
                pageCssClass={'page-omoss'}
              />
              <RouteWithMeta
                path='/anvandarvillkor'
                exact
                component={Anvandarvillkor}
                description={siteDescription}
                fields={utils.getDocument('pages_' + language, 'anvandarvillkor')}
                globalSettings={globalSettings}
                casesList={casesList}
                pageCssClass={'page-anvandarvillkor'}
              />
              <RouteWithMeta
                path='/cookies'
                exact
                component={Cookies}
                description={siteDescription}
                fields={utils.getDocument('pages_' + language, 'cookies')}
                globalSettings={globalSettings}
                casesList={casesList}
                pageCssClass={'page-cookies'}
              />
              <RouteWithMeta
                path='/avtalsmallar'
                exact
                component={AvtalsmallarList}
                description={siteDescription}
                fields={utils.getDocument('pages_' + language, 'avtalsmallar')}
                globalSettings={globalSettings}
                avtalsmallar={avtalsmallar}
                pageCssClass={'page-avtalsmallar'}
              />

              {nyheterList.map((nyheter, index) => {
                const path = slugify(`/nyheter/${nyheter.title}`)
                const nextPost = nyheterList[index - 1]
                const prevPost = nyheterList[index + 1]
                return (
                  <RouteWithMeta
                    key={path}
                    path={path}
                    exact
                    component={Nyheter}
                    individualNyheter={nyheter}
                    globalSettings={globalSettings}
                    fields={utils.getDocument('pages_' + language, 'nyheter')}
                    pageCssClass={'page-nyheter'}
                    nextPostURL={nextPost && slugify(`/cases/${nextPost.title}/`)}
                    prevPostURL={prevPost && slugify(`/cases/${prevPost.title}/`)}
                  />
                )
              })}
              {casesList.map((individualcase, index) => {
                const path = slugifyNonKebab(`/cases/${individualcase.title}`)
                const nextPost = casesList[index - 1]
                const prevPost = casesList[index + 1]
                return (
                  <RouteWithMeta
                    key={path}
                    path={path}
                    exact
                    component={Case}
                    individualcase={individualcase}
                    globalSettings={globalSettings}
                    pageCssClass={'page-cases'}
                    nextPostURL={nextPost && slugifyNonKebab(`/cases/${nextPost.title}/`)}
                    prevPostURL={prevPost && slugifyNonKebab(`/cases/${prevPost.title}/`)}
                  />
                )
              })}
              {avtalsmallar.map((avtal, index) => {
                const path = slugify(`/cases/${avtal.title}`)
                const nextPost = avtalsmallar[index - 1]
                const prevPost = avtalsmallar[index + 1]
                return (
                  <RouteWithMeta
                    key={path}
                    path={path}
                    exact
                    component={Case}
                    individualAvtal={avtal}
                    globalSettings={globalSettings}
                    pageCssClass={'page-avtalsmallar'}
                    nextPostURL={nextPost && slugify(`/cases/${nextPost.title}/`)}
                    prevPostURL={prevPost && slugify(`/cases/${prevPost.title}/`)}
                  />
                )
              })}

              <Route render={() => <NoMatch siteUrl={siteUrl} />} />
            </Switch>
          </ScrollToTop>
        </Router>
      </>
    )
  }
}

export default App
