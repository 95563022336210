import React from 'react';
import { slugify, slugifyNonKebab } from '../util/url';
import { NavLink } from 'react-router-dom';
import RenderField from "../components/RenderField";
export default class CasesList extends React.Component {
    render() {
        var casesList = this.props.casesList;
        var casestoRender = casesList.map((individualcase, index) => {
            const path = slugifyNonKebab(`/cases/${individualcase.title}/`);
            return (
                <React.Fragment key={individualcase.title}>
                    <article>
                        <NavLink to={slugifyNonKebab(`/cases/${individualcase.title}/`)}>
                            {typeof individualcase.postFeaturedImage !== "undefined"
                                && <a href={path}> <img src={individualcase.postFeaturedImage} /></a>
                            }
                            <p className="case-title">{individualcase.title}</p>
                            <RenderField source={individualcase.excerpt} />
                        </NavLink>
                    </article>
                </React.Fragment>
            )
        })
        return (
            <>
                <main>
                    <h1>Cases</h1>
                    {casestoRender}
                </main>
            </>
        )
    }
}