import data from '../../src/data.json';
var util = {
    getDocument: function (collection, name) {
        return data[collection] && data[collection].filter(page => page.name === name)[0]
    },
    getDocuments: function (collection) {
        return data[collection] || [];
    },
    getLanguage: function () {
        var language = localStorage.getItem('language');
        if (typeof language !== "undefined" && language !== null) {
            return language;
        }
        return "sv-se";
    },
    getLanguageUrl: function (language) {
        var languageURL = '';
        if (language.split("-").length > 0) {
            return languageURL = language.split("-")[0];
        }
    }

}


export default util;