import React from 'react';
import RenderField from "../components/RenderField";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Footer from "../components/Footer"
export default class Tjansten extends React.Component {

    render() {
        var fields = this.props.fields;
        var tableDataArray = fields.tableData;
        console.log(tableDataArray);
        var slickSettings = {
            dots: true,
            infinite: false,
            speed: 300,
            slidesToShow: 1,
            adaptiveHeight: true,
            arrows: true
        }
        return (
            <>
                <main>
                    <section className="tjansten-01">
                        <img src={fields.tjanstenHeaderImage} />
                        <h1>{fields.section1Head}</h1>
                        <div className="text">
                            <RenderField source={fields.section1Desc} />
                        </div>
                        <div className="contactform">
                            <form>
                                <input type="email" placeholder="Ange epostadress" />
                                <button className="button button-blue">{fields.contactBtn}</button>
                            </form>
                        </div>
                    </section>
                    <hr className="blue" />
                    <section className="tjansten-02">
                        <div className="carousel col-2">

                            <Slider className="single-item" {...slickSettings}>
                                <div className="screen screen-01">
                                    <img src="/images/iphone-screen-enklare-1.png" alt="" id="screen-01" />
                                </div>
                                <div className="screen screen-02">
                                    <img src="/images/iphone-screen-enklare-2.png" alt="" id="screen-02" />
                                </div>
                                <div className="screen screen-03">
                                    <img src="/images/iphone-screen-enklare-3.png" alt="" id="screen-03" />
                                </div>
                                <div className="screen screen-04">
                                    <img src="/images/iphone-screen-enklare-3.png" alt="" id="screen-04" />
                                </div>
                            </Slider>
                        </div>
                        <div className="text col-2">
                            <h2>{fields.section2Head}</h2>
                            <RenderField source={fields.section2Desc} />
                            <i><RenderField source={fields.section2Link} /></i>
                        </div>
                    </section>
                    <hr className="blue" />
                    <section className="tjansten-03">
                        <div className="carousel col-2">
                            <Slider className="single-item" {...slickSettings}>
                                <div className="screen screen-01">
                                    <img src="/images/iphone-screen-enklare-1.png" alt="" id="screen-01" />
                                </div>
                                <div className="screen screen-02">
                                    <img src="/images/iphone-screen-enklare-2.png" alt="" id="screen-02" />
                                </div>
                                <div className="screen screen-03">
                                    <img src="/images/iphone-screen-enklare-3.png" alt="" id="screen-03" />
                                </div>
                                <div className="screen screen-04">
                                    <img src="/images/iphone-screen-enklare-3.png" alt="" id="screen-04" />
                                </div>
                            </Slider>
                        </div>
                        <div className="text col-2">
                            <h2>{fields.section3Head}</h2>
                            <RenderField source={fields.section3Desc} />
                            <div className="customer-quote"><RenderField source={fields.section3Quote} /></div>
                            <div className="customer">
                                <p><img src="/images/dummy-client.png?73532c91b02127e772dab10e4b3c9bef" alt="" /></p>
                                <RenderField source={fields.section3NameFields} />
                            </div>
                        </div>
                    </section>
                    <hr className="blue" />
                    <section className="tjansten-04">
                        <div className="image col-2">
                            <img src="/images/illustration-signerade-avtal.svg" alt="" />
                        </div>
                        <div className="text col-2">
                            <h2>{fields.section4Head}</h2>
                            <RenderField source={fields.section4DescOne} />
                            <RenderField source={fields.section4DescTwo} />
                        </div>
                    </section>
                    <hr className="blue" />
                    <section className="tjansten-05">
                        <h2>{fields.section5Head}</h2>
                        <p><img src="/images/illustration-vara-omraden.svg" alt="" /></p>
                    </section>
                    <hr className="blue" />
                    <section className="tjansten-06">
                        <h2>{fields.section6Head}</h2>
                        <table className="table-functions">
                            <thead>
                                <tr>
                                    <th></th>
                                    <th></th>
                                    <th className="rotate">
                                        <div>{fields.tableHead1}</div>
                                    </th>
                                    <th className="rotate">
                                        <div>{fields.tableHead2}</div>
                                    </th>
                                    <th className="rotate">
                                        <div>{fields.tableHead3}</div>
                                    </th>
                                    <th className="rotate">
                                        <div>{fields.tableHead4}</div>
                                    </th>
                                </tr>
                            </thead>
                            <tbody>
                                {
                                    tableDataArray.map(
                                        (tableData, index) => {
                                            return (
                                                <tr>
                                                    <td><RenderField source={tableData.tdDataHead} /></td>
                                                    <td><RenderField source={tableData.tdDataDesc} /></td>
                                                    <td><RenderField source={tableData.tdHeadValue1} /></td>
                                                    <td><RenderField source={tableData.tdHeadValue2} /></td>
                                                    <td><RenderField source={tableData.tdHeadValue3} /></td>
                                                    <td><RenderField source={tableData.tdHeadValue4} /></td>
                                                </tr>

                                            )
                                        }
                                    )
                                }
                                <tr>
                                    <td data-sheets-value="{'1':2,'2':'Kreditkontroll'}"><RenderField source={fields.tdHead33} /></td>
                                    <td data-sheets-value="{'1':2,'2':'Möjlighet att integrera en kreditkontroll i ditt arbetsflöde. Egreement har färdiga med färdiga kopplingar till bl.a UC, Bisnode, Creditsafe och Sergel.'}">
                                        <RenderField source={fields.tdDesc33} />
                                    </td>
                                    <td>✓</td>
                                    <td>✓</td>
                                    <td>✓</td>
                                    <td>✓</td>
                                </tr>
                                <tr>
                                    <td data-sheets-value="{'1':2,'2':'Adresslagning'}"><RenderField source={fields.tdHead34} /></td>
                                    <td data-sheets-value="{'1':2,'2':'Integrera en adresslagning i ditt arbetsflöde vilket förbättrar datakvaliten och användarupplevelsen.'}">
                                        <RenderField source={fields.tdDesc34} />
                                    </td>
                                    <td>✓</td>
                                    <td>✓</td>
                                    <td>✓</td>
                                    <td>✓</td>
                                </tr>
                                <tr>
                                    <td data-sheets-value="{'1':2,'2':'IP restriktion'}"><RenderField source={fields.tdHead35} /></td>
                                    <td data-sheets-value="{'1':2,'2':'Begränsa möjligheten att öppna avtalet till en viss IP-adress i syfte att begränsa åtkomsten.'}">
                                        <RenderField source={fields.tdDesc35} />
                                    </td>
                                    <td>✓</td>
                                    <td>✓</td>
                                    <td>✓</td>
                                    <td>✓</td>
                                </tr>
                                <tr>
                                    <td data-sheets-value="{'1':2,'2':'Validering'}"><RenderField source={fields.tdHead36} /></td>
                                    <td data-sheets-value="{'1':2,'2':'Vi säkerställer att formaten för telefonnummer, e-post, bankkontonummer är korrekta enligt rådande standard.'}">
                                        <RenderField source={fields.tdDesc36} />
                                    </td>
                                    <td>✓</td>
                                    <td>✓</td>
                                    <td>✓</td>
                                    <td>✓</td>
                                </tr>
                                <tr>
                                    <td data-sheets-value="{'1':2,'2':'Strukturerad metadata'}"><RenderField source={fields.tdHead37} /></td>
                                    <td data-sheets-value="{'1':2,'2':'Vi hjälper till att sätta upp flöden för att skicka strukturerad metadata från datainsamlingen.'}">
                                        <RenderField source={fields.tdDesc37} />
                                    </td>
                                    <td>✓</td>
                                    <td>✓</td>
                                    <td>✓</td>
                                    <td>✓</td>
                                </tr>
                                <tr>
                                    <td data-sheets-value="{'1':2,'2':'Hosting'}"><RenderField source={fields.tdHead38} /></td>
                                    <td data-sheets-value="{'1':2,'2':'Vi tar hand om er formulärsida genom att ansvara för att den ligger uppe och framtida underhåll.'}">
                                        <RenderField source={fields.tdDesc38} />
                                    </td>
                                    <td>✓</td>
                                    <td>✓</td>
                                    <td>✓</td>
                                    <td>✓</td>
                                </tr>
                                <tr>
                                    <td data-sheets-value="{'1':2,'2':'Skapa dynamisk PDF'}"><RenderField source={fields.tdHead39} /></td>
                                    <td data-sheets-value="{'1':2,'2':'Vi skapar dynamiska pdfer utifrån den insamlade datan vilket innebär att vi kan autoifylla och skapa en komplett ifylld blankett.'}">
                                        <RenderField source={fields.tdDesc39} />
                                    </td>
                                    <td>✓</td>
                                    <td>✓</td>
                                    <td>✓</td>
                                    <td>✓</td>
                                </tr>
                                <tr>
                                    <td data-sheets-value="{'1':2,'2':'Automatisk el manuell delete tex'}"><RenderField source={fields.tdHead40} /></td>
                                    <td data-sheets-value="{'1':2,'2':'Vi hjälper er att följa dataskyddsförordning 'GDPR' genom bl.a inbyggda stöd för rättning, borttagning och gallring. Dessutom kan ni bestämma i förväg när avtalet ska raderas eller välja att radera det manuellt efter en viss tid. (Ev behöver vi skapa en undersida med mer information och då finns det lite exempel här: https://www.egreement.com/sv/nyheter/information-om-gdpr)'}">
                                        <RenderField source={fields.tdDesc40} />
                                    </td>
                                    <td>✓</td>
                                    <td>✓</td>
                                    <td>✓</td>
                                    <td>✓</td>
                                </tr>
                            </tbody>
                        </table>
                    </section>
                </main>
            </>
        );
    }
}