import React from 'react';
import ReactDOM from 'react-dom';
export default class OverlayPopup extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            overlayShow: this.props.overlayShow === "undefined" ? false : this.props.overlayShow,
            closeOption: true,
            portalAdd: this.props.portalAdd === "undefined" ? false : this.props.portalAdd
        }
        this.overlayRef = React.createRef();
        if (props.overlayShow && props.closeOption) {
            document.addEventListener('mousedown', this.handleClicks, false);
        }
    }

    handleClicks = (e) => {
        //Check if Click is within the overlay popup
        const overlay = this.overlayRef;
        if (overlay.current !== null) {
            if (overlay.current.contains(e.target)) {
                return;
            }
        }
        //If not close the  overlay popup
        this.props.closeoverlayWindow();
    }

    componentWillUnmount = () => {
        document.removeEventListener('mousedown', this.handleClicks, false);
    }

    static getDerivedStateFromProps(nextProps, prevState) {
        // do things with nextProps.someProp and prevState.cachedSomeProp
        return {
            overlayShow: nextProps.overlayShow,
        };

    }


    render() {
        var component = this.props.component;
        let showClass = '';
        var closeButton = null;
        if (this.state.overlayShow) {
            showClass = 'overlay-show';
        }
        if (this.props.closeOption) {
            closeButton = <button className="close" onClick={this.props.closeoverlayWindow}>
                Stäng
        </button>
        }

        var overlayPopup = <div className={"overlay " + showClass} >
            <div className="overlay-container" ref={this.overlayRef}>
                {closeButton}
                {component}
            </div>
        </div>
        let overlayPopupDiv = document.getElementById('overlayPopup');

        if (typeof overlayPopupDiv !== "undefined" && overlayPopupDiv !== null &&
            this.props.overlayShow && this.state.portalAdd) {
            return ReactDOM.createPortal(
                overlayPopup,
                overlayPopupDiv
            );
        }
        else if (!this.state.portalAdd) {
            return overlayPopup;
        }
        return null;

    }
}