import React from 'react';
export default class Button extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            overlayShow: false,
        };
    }

    showoverlayWindow = () => {
        this.setState({
            overlayShow: true
        })
    }

    closeoverlayWindow = () => {
        this.setState({
            overlayShow: false
        })
    }

    render() {
        let overlayWindow = this.props.overlay;
        var buttonCompoent = null;
        if (typeof overlayWindow !== "undefined" && overlayWindow !== null) {
            overlayWindow = React.cloneElement(overlayWindow, { overlayShow: this.state.overlayShow, closeoverlayWindow: this.closeoverlayWindow })
            buttonCompoent = <button className={this.props.className} onClick={this.showoverlayWindow}>
                {this.props.text}
            </button>
        }
        else {
            buttonCompoent = <button className={this.props.className}>
                {this.props.text}
            </button>

        }
        if(!this.state.overlayShow){
            overlayWindow = null;
        }
        return (
            <>
                {overlayWindow}
                {buttonCompoent}
            </>
        )
    }
}