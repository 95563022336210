import React from 'react';
import RenderField from "../components/RenderField";
export default class Support extends React.Component {
    render(){
        var fields = this.props.fields;
        return (
            <main>
                <article>
                <RenderField source={fields.SupportText}/>
                </article>
            </main>
        )
    }
}