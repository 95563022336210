import React from 'react';
import { slugify } from '../util/url';
import { NavLink } from 'react-router-dom';
import RenderField from "../components/RenderField";

export default class Avtalsmallar extends React.Component {
    render() {
        var individualAvtal = this.props.individualAvtal;
        console.log(individualAvtal);
        return (
            <>
                <div>
                    <RenderField source={individualAvtal.content} />
                </div>
            </>
        )
    }
}