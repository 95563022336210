import React from 'react';
import util from '../../src/util/util';
import RenderField from "../components/RenderField";

export default class ContactFormFullOverlay extends React.Component {

    constructor(props) {
        super(props);
        this.state = {};
        this.form = React.createRef();
    }

    handleInputChange = (event) => {
        const target = event.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.id;
        const validitity = target.checkValidity();
        this.setState({
            [name]: value,
            [name + "validitity"]: validitity
        });
    }

    handleSubmit = (event) => {
        console.log(this.form);
        var formDOM = this.form.current;
        var formInput = formDOM.getElementsByTagName("input");
        var isFormValid = true;
        formDOM.checkValidity();
        for (var i = 0; i < formInput.length; i++) {
            var formInputValidity = formInput[i].checkValidity();
            if (!formInputValidity) {
                isFormValid = false;
                return;
            }
        }

        if (isFormValid) {
            var data = {};
            data["name"] = this.state.name;
            data["_replyto"] = this.state.email;
            data["companyName"] = this.state.company;
            data["message"] = this.state.message;
            data["telephone"] = this.state.phone;
            data["usersCount"] = this.state.users;
            data["contracts"] = this.state.contracts;
            fetch('https://formspree.io/xbayzjkm', {
                method: 'post',
                body: JSON.stringify(data)
            }).then(function (response) {
                if (response.ok) {
                    console.log("success");
                }
            })
        }
        event.preventDefault();

    }

    render() {
        var fields = null;
        var type = "contactFormFull01";
        var language = util.getLanguage();
        if (this.props.type !== "undefined") {
            type = this.props.type;
        }
        if (typeof this.props.fields === "undefined") {
            fields = util.getDocument('component_' + language, type);
        }
        else {
            fields = this.props.fields;
        }
        return (
            <>
                <div>
                    <h2>{fields.contactFormHead}</h2>
                    <RenderField source={fields.contactFormPara} />
                    <ul>
                        <RenderField source={fields.contactFormList} />
                    </ul>
                </div>
                <div>
                    <div className="contactform contactform__full">
                        <form ref={this.form}>
                            <div className="form-container">
                                <label for="name">{fields.formNameLabel}</label>
                                <input type="text" id="name" placeholder={fields.formNamePlaceholder} onChange={this.handleInputChange}
                                    value={this.state.name} required onInvalid={(e) => e.target.setCustomValidity(fields.formNameErr)}
                                    onInput={(e) => e.target.setCustomValidity('')} />

                                <label for="email">{fields.formEmailLabel}</label>
                                <input type="email" id="email" placeholder={fields.formEmailPlaceholder} onChange={this.handleInputChange}
                                    value={this.state.email} required onInvalid={(e) => e.target.setCustomValidity(fields.formEmailErr)}
                                    onInput={(e) => e.target.setCustomValidity('')} />

                                <label for="company">{fields.formCompanyLabel}</label>
                                <input type="text" id="company" placeholder={fields.formCompanyPlaceholder} onChange={this.handleInputChange}
                                    value={this.state.company} required onInvalid={(e) => e.target.setCustomValidity(fields.formCompanyErr)}
                                    onInput={(e) => e.target.setCustomValidity('')} />

                                <label for="users">{fields.formUserLabel}</label>
                                <input type="number" id="users" placeholder={fields.formUserPlaceholder} onChange={this.handleInputChange}
                                    value={this.state.users} required 
                                    onInvalid={(e) => e.target.setCustomValidity(fields.formUserErr)}
                                    onInput={(e) => e.target.setCustomValidity('')} />

                                <label for="contracts">{fields.formContractsLabel}</label>
                                <input type="number" id="contracts" placeholder={fields.formContractsPlaceholder} onChange={this.handleInputChange}
                                    value={this.state.contracts} required />

                                <label for="phone">{fields.formphoneLabel}</label>
                                <input type="tel" id="phone" placeholder={fields.formphonePlaceholder} onChange={this.handleInputChange}
                                    value={this.state.phone} required onInvalid={(e) => e.target.setCustomValidity(fields.formphoneErr)}
                                    onInput={(e) => e.target.setCustomValidity('')} />
                                <label for="textarea">{fields.formMoreInfo}</label>
                                <textarea id="message" rows="3" onChange={this.handleInputChange}
                                    value={this.state.message}></textarea>

                            </div>
                            <p className="terms">
                                {fields.contactFormTerms}
                            </p>
                            <button className="button button-blue" onClick={this.handleSubmit}>{fields.contactFormButton}</button>
                        </form>
                    </div>
                </div>
            </>
        )
    }

}