import React from 'react';
import { slugify } from '../util/url';
import { NavLink } from 'react-router-dom';
export default class AvtalsmallerList extends React.Component {
    render() {
        var avtalsmallar = this.props.avtalsmallar;
        var avtalsmallarToRender = avtalsmallar.map((avtal, index) => {
            return (
                <>
                    <div>
                        <NavLink to={slugify(`/nyheter/${avtal.title}/`)}><h1>{avtal.title}</h1></NavLink>
                        {avtal.excerpt}
                    </div>
                </>
            )
        })
        return (
            <>
                {avtalsmallarToRender}
            </>
        )
    }
}