import React from 'react';
import RenderField from "../components/RenderField";
import { slugify, slugifyNonKebab } from '../util/url';
export default class Case extends React.Component {
    render() {
        var individualcase = this.props.individualcase;
        if (typeof individualcase === "undefined" && this.props.fields !== "undefined") {
            individualcase = this.props.fields;
        }
        const path = slugifyNonKebab(`/cases/${individualcase.title}`)
        return (
            <>
                <main>
                    <article>
                        {typeof individualcase.postFeaturedImage !== "undefined" &&
                            <img src={individualcase.postFeaturedImage} />
                        }
                        <RenderField source={individualcase.content} />
                    </article>
                </main>
            </>
        )
    }
}