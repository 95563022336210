import React from 'react';
import RenderField from "../components/RenderField";
import AOS from '../aos/aos';
import 'aos/src/sass/aos.scss';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";
import ContactFormOverlay from "../components/ContactFormOverlay";
import ContactFormFullOverlay from "../components/ContactFormFullOverlay";
import OverlayPopup from "../components/OverlayPopup";
import Button from "../components/Button";
import { slugify, slugifyNonKebab } from '../util/url';
import { NavLink } from 'react-router-dom';
export default class HomePageContent extends React.Component {
  componentDidMount() {
    var screens = document.getElementsByClassName("screens");
    if (typeof screens !== "undefined" && screens !== null && screens.length !== 0) {
      AOS.init({
        duration: 1200,
        easing: 'ease-in-out-back',
        container: ".screens",
        mirror: true,
        debounceDelay: 10
      });
    }
  }
  render() {
    var fields = this.props.fields;
    var ClientImages = fields.ClientImages;
    console.log(ClientImages)
    var slickSettings = {
      infinite: true,
      speed: 300,
      slidesToShow: 4,
      slidesToScroll: 4,
      adaptiveHeight: true,
      arrows: true,
      responsive: [
        {
          breakpoint: 1024,
          settings: {
            slidesToShow: 3,
            slidesToScroll: 3,
            infinite: false,
            dots: true
          }
        },
        {
          breakpoint: 600,
          settings: {
            slidesToShow: 2,
            slidesToScroll: 2
          }
        }
        ,
        {
          breakpoint: 600,
          settings: {
            slidesToShow: 2,
            slidesToScroll: 2
          }
        }
        // You can unslick at a given breakpoint now by adding:
        // settings: "unslick"
        // instead of a settings object
      ]
    }
    return (
      <main>
        <section className="start-animation">
          <RenderField source={fields.spanHeaderOnImageDesc} />
          <div className="phone">
            <div className="screens">
              <div className="screen screen-01">
                <img src={fields.iphoneScreen1} alt="" id="screen-01" />
              </div>
              <div className="screen screen-02">
                <img src={fields.iphoneScreen2} alt="" id="screen-02" />
              </div>
              <div className="screen screen-03">
                <img src={fields.iphoneScreen3} alt="" id="screen-03" />
              </div>
              <div className="teaser teaser-01-01" data-aos="fade-up" data-aos-anchor=".screen-01"
                data-aos-anchor-placement="top-bottom">
                <h3>{fields.headerEgreement}</h3>
                <RenderField source={fields.headerEgreementLink} />
              </div>
              <div className="teaser teaser-01-02" data-aos="fade-up" data-aos-anchor=".screen-01"
                data-aos-anchor-placement="top-bottom">
                <h3>{fields.screen1header}</h3>
                <RenderField source={fields.screen1description} />
              </div>
              <div className="teaser teaser-02-02" data-aos="fade-up" data-aos-anchor=".screen-02"
                data-aos-anchor-placement="top-bottom">
                <h3>{fields.screen2header}</h3>
                <RenderField source={fields.screen2description} />
              </div>
              <div className="teaser teaser-02-03" data-aos="fade-up" data-aos-anchor=".screen-02"
                data-aos-anchor-placement="top-bottom">
                <h3>{fields.screen3header}</h3>
                <RenderField source={fields.screen3description} />
              </div>
              <div className="teaser teaser-02-02" data-aos="fade-up" data-aos-anchor=".screen-03"
                data-aos-anchor-placement="top-bottom">
                <h3>{fields.screen3header1}</h3>
                <RenderField source={fields.screen3description1} />
              </div>
              <div className="teaser teaser-02-03" data-aos="fade-up" data-aos-anchor=".screen-03"
                data-aos-anchor-placement="top-bottom">
                <h3>{fields.screen3header2}</h3>
                <RenderField source={fields.screen3description2} />
              </div>
            </div>
          </div>
          <div className="content">
            <h2>{fields.contentHead}</h2>
            <p><RenderField source={fields.contentdescription} /></p>
          </div>
        </section>
        <hr className="orange" />
        <section className="start-02">
          <h2>{fields.subscribeHead}</h2>
          <div className="teasers">
            <div className="teaser col-3">
              <h3>{fields.subscribe1head}</h3>
              <div className="price">{fields.subscribe1price}</div>
              <ul className="checklist">
                <RenderField source={fields.subscribe1checklist} />
              </ul>
              <Button className="button button-green" overlay={<OverlayPopup component={<ContactFormOverlay type="contactFormSimple01" />} portalAdd={true}
                closeOption={true} />}
                text={fields.subscribeButton1} >
              </Button>
              <RenderField className="more-info" source={fields.moreInfo1} />
            </div>
            <div className="teaser col-3">
              <h3>{fields.subscribe2head}</h3>
              <div className="price">{fields.subscribe2price}</div>
              <ul className="checklist">
                <RenderField source={fields.subscribe2checklist} />

              </ul>
              <Button className="button button-green" overlay={<OverlayPopup component={<ContactFormOverlay type="contactFormSimple02" />} portalAdd={true}
                closeOption={false} />}
                text={fields.subscribeButton2}>

              </Button>
              <RenderField className="more-info" source={fields.moreInfo2} />
            </div>
            <div className="teaser col-3">
              <h3>{fields.subscribe3head}</h3>
              <div className="price">{fields.subscribe3price}</div>
              <ul className="checklist">
                <RenderField source={fields.subscribe3checklist} />

              </ul>
              <Button className="button button-green" overlay={<OverlayPopup component={<ContactFormFullOverlay type="contactFormFull01" />} portalAdd={true}
                closeOption={true} />} text={fields.subscribeButton3}></Button>
              <RenderField className="more-info" source={fields.moreInfo3} />
            </div>
          </div>
        </section>
        <hr className="orange" />
        <section className="start-03">
          <h2>{fields.popularAdditionHead}</h2>
          <div className="teasers">
            <div className="teaser col-4">
              <img src={fields.digitalisera_blanketter} alt="" />
              <h3>{fields.popularAdditionHead1}</h3>
              <RenderField source={fields.popularAdditiondescription1} />
              <button className="button button-green">{fields.popularAdditionBtn1}</button>
            </div>
            <div className="teaser col-4">
              <img src={fields.firmateckningskontroll} alt="" />
              <h3>{fields.popularAdditionHead2}</h3>
              <RenderField source={fields.popularAdditiondescription2} />
              <button className="button button-green">{fields.popularAdditionBtn2}</button>
            </div>
            <div className="teaser col-4">
              <img src={fields.smsNotifiering} alt="" />
              <h3>{fields.popularAdditionHead3}</h3>
              <RenderField source={fields.popularAdditiondescription3} />
              <button className="button button-green">{fields.popularAdditionBtn3}</button>
            </div>
            <div className="teaser col-4">
              <img src={fields.massutskick} alt="" />
              <h3>{fields.popularAdditionHead4}</h3>
              <RenderField source={fields.popularAdditiondescription4} />
              <button className="button button-green">{fields.popularAdditionBtn4}</button>
            </div>
          </div>
        </section>
        <hr className="orange" />
        <section className="start-03">
          <h2>{fields.identificationHead}</h2>
          <div className="teasers">
            <div className="teaser col-4">
              <img src={fields.inloggningmedelegitimation} alt="" />
              <h3>{fields.identificationHead1}</h3>
              <RenderField source={fields.identificationdescription1} />
              <button className="button button-green">{fields.identificationBtn1}</button>
            </div>
            <div className="teaser col-4">
              <img src={fields.identifieringPerTelefon} alt="" />
              <h3>{fields.identificationHead2}</h3>
              <RenderField source={fields.identificationdescription2} />
              <button className="button button-green">{fields.identificationBtn2}</button>
            </div>
            <div className="teaser col-4">
              <img src={fields.egetCertifikat} alt="" />
              <h3>{fields.identificationHead3}</h3>
              <RenderField source={fields.identificationdescription3} />
              <button className="button button-green">{fields.identificationBtn3}</button>
            </div>
            <div className="teaser col-4">
              <img src={fields.identifieringforAtkomst_av_avtal} alt="" />
              <h3>{fields.identificationHead4}</h3>
              <RenderField source={fields.identificationdescription4} />
              <button className="button button-green">{fields.identificationBtn4}</button>
            </div>
          </div>
        </section>
        <hr className="orange" />
        <section className="start-04">
          <RenderField source={fields.customerreviewHead} />
          <div className="case-study">
            <div className="client">
              <div>
                <strong>{fields.clientField}</strong><br />
                {fields.clientName}
              </div>
              <img src={fields.dummyClient} alt="" />
            </div>
            <div className="case-study-description">
              <RenderField source={fields.caseStudyDescription} />
            </div>
          </div>
        </section>
        <hr className="orange" />
        <section className="start-05">
          <Slider className="client-logos multiple-items" {...slickSettings}>
            {
              ClientImages.map(
                (clientImage, index) => {
                  return (
                    <React.Fragment>
                      {clientImage.image ? <div>
                        <NavLink to={slugifyNonKebab(`/cases/${clientImage.cases}/`)}>
                          < img src={clientImage.image} alt="" /></NavLink></div> : null}
                    </React.Fragment>
                  )
                }
              )
            }
          </Slider>
        </section>
        <hr className="orange" />
        <section className="start-06">
          <div>
            <img src={fields.finger} alt="" />
          </div>
          <h2>{fields.signContractHead}</h2>
          <RenderField source={fields.signContractDesc} />
          <button className="button button-green">{fields.signContractBtn}</button>
        </section>
        <hr className="orange" />
        <section className="start-07">
          <h2>{fields.signServicesContract}</h2>
          <div className="link-lists">
            <ul className="link-list col-3">
              <RenderField source={fields.serviceName1} />
            </ul>
            <ul className="link-list col-3">
              <RenderField source={fields.serviceName2} />
            </ul>
            <ul className="link-list col-3">
              <RenderField source={fields.serviceName3} />
            </ul>
          </div>
          <div className="contactform">
            <p>{fields.contactHead}</p>
            <form>
              <input type="email" placeholder="Ange epostadress" />
              <button className="button button-blue">{fields.contactBtn}</button>
            </form>
            <p className="terms">{fields.contactTerms}</p>
          </div>
        </section>
        <hr className="orange" />
        <section className="start-08">
          <div>
            <img src={fields.stockPhotoWoman1} alt="" />
          </div>
          <div className="text">
            <h2><RenderField source={fields.egreementConnectHead} /></h2>
            <RenderField source={fields.egreementConnectDesc} />
            <button className="button button-green">{fields.egreementConnectBtn}</button>
          </div>
        </section>
        <hr className="orange" />
        <section className="start-09">
          <div className="customer-quote">
            <RenderField source={fields.customerQuote} />
          </div>
          <img src={fields.dummyClient} alt="" />

          <div className="customer">
            <strong>{fields.customerFieldname}</strong><br />
            {fields.customersName}
          </div>

        </section>
        <hr className="orange" />
        <section>
          Kod

          </section>
        <hr className="orange" />
        <section className="start-11">
          <div>
            <img src={fields.stockPhotoMan1} alt="" />
          </div>
          <div className="text">
            <h2><RenderField source={fields.signContractHead2} /></h2>
            <RenderField source={fields.signContractDesc2} />
          </div>

        </section>
        <hr className="orange" />
        <section className="start-12">
          <div className="text">
            <h2>{fields.signContractHead3}</h2>
            <RenderField source={fields.signContractDesc3} />
            <div className="contactform">
              <form className="email-form-simple">
                <input type="email" placeholder="Ange epostadress" />
                <button className="button button-blue">{fields.signContractBtn3}</button>
              </form>
            </div>
          </div>
          <div className="image">
            <img src={fields.finger} alt="" />
          </div>
        </section>
      </main>
    )
  }
}