import React from 'react';
import { slugify } from '../util/url';
import { NavLink } from 'react-router-dom';
import RenderField from "../components/RenderField";
export default class Nyheter extends React.Component {
    render() {
        var individualNyheter = this.props.individualNyheter;
        console.log(individualNyheter);
        return (
            <><main>
                <article>
                    <RenderField source={individualNyheter.content} />
                </article>
            </main>
            </>
        )
    }
}