import React from 'react';
import RenderField from "../components/RenderField";
export default class Partner extends React.Component {
    
    render(){
        var fields = this.props.fields;
        return (
            <main>
                <article>
                    <RenderField  source={fields.PartnersText}/>
                </article>
            </main>
        )
    }
}