import React from 'react';
import RenderField from "../components/RenderField";
export default class Anvandarvillkor extends React.Component {
    render() {
        var fields = this.props.fields;
        return (
            <main>
                <article>
                    <RenderField source={fields.anvandarvillkorText} />
                </article>
            </main>
        )
    }
}