import React from 'react';
import util from '../../src/util/util';
import RenderField from "../components/RenderField";

export default class ContactFormOverlay extends React.Component {
    render() {
        var fields = null;
        var type = "contactFormSimple01";
        var language = util.getLanguage();
        if (this.props.type !== "undefined") {
            type = this.props.type;
        }
        if (typeof this.props.fields === "undefined") {
            fields = util.getDocument('component_' + language, type);
        }
        else {
            fields = this.props.fields;
        }

        return (
            <React.Fragment>
                <div>
                    <h2>{fields.contactformHead}</h2>
                    <RenderField source={fields.contactformPara} />
                </div>
                <div>
                    <div className="customer">
                        <p><img src={fields.contactCustomerImage} alt="" /></p>
                        <RenderField source={fields.contactCustomerName} />
                    </div>
                    <div className="popover popover-bottom popover-brand-color">
                        <div className="popover-arrow"></div>
                        <div className="popover-body">
                            <RenderField source={fields.contactPopoverPara1} />
                            <RenderField source={fields.contactPopoverPara2} />
                        </div>
                    </div>
                    <div className="contactform">
                        <form>
                            <input type="email" placeholder="070-XXX XX XX" />
                            <button className="button button-green">{fields.contactFormBtn}
                            </button>
                        </form>
                        <RenderField source={fields.contactFooterPara1} />
                        <p className="terms"><RenderField source={fields.contactFooterPara2} renderEncloser='span' /></p>
                    </div>
                </div>
            </React.Fragment>
        )
    }
}