import React from 'react';
import util from "../util/util";
import { NavLink, withRouter } from 'react-router-dom';
class Navbar extends React.Component {

    getNavLinkClass = (path) => {
        return this.props.location.pathname.includes(path) ? 'active' : '';
    }
    render() {
        var language = util.getLanguage();
        let fields = null;
        if (typeof this.props.fields === "undefined") {
            fields = util.getDocument('component_' + language, 'navbar')
        }
        else {
            fields = this.props.fields;
        }
        return (
            <header>
                <label htmlFor="toggleNavMain" className="toggle-nav toggle-nav-main"></label>
                <input type="checkbox" id="toggleNavMain" name="toggleNavMain" defaultChecked={false}/>
                <label htmlFor="toggleNavUser" className="toggle-nav toggle-nav-user"></label>
                <input type="checkbox" id="toggleNavUser" name="toggleNavUser" defaultChecked={false}/>
                <a className="logo" href="/">
                    <svg width="25" height="22" viewBox="0 0 25 22" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path fillRule="evenodd" clipRule="evenodd" d="M11.5473 5.9607C12.2604 5.45445 13.0203 5.05824 13.8003 4.77606C16.3489 3.94721 17.7735 4.33206 18.2887 4.90291C18.6334 5.28481 18.5078 5.73575 18.4023 5.97058C17.3548 8.30269 12.7856 9.46328 11.3142 9.69413C11.3142 9.69413 10.0518 9.92602 8.37347 9.94888C8.9585 8.40993 10.0641 7.01376 11.5473 5.9607ZM21.2108 12.4727C20.5736 13.6782 19.6115 14.7593 18.4285 15.5992C16.7162 16.8148 14.7355 17.4072 12.8496 17.2688C11.1145 17.1409 9.67711 16.396 8.80259 15.171C8.59195 14.8758 8.41933 14.5587 8.28338 14.2243C10.2701 14.2094 11.6855 13.9683 11.9865 13.9126C12.3189 13.8604 14.1206 13.5562 16.1263 12.7964C19.3073 11.5917 21.6772 9.64959 22.6214 7.46056C23.5788 5.241 23.2197 3.11674 21.6879 1.73237C20.8735 0.996451 19.5863 0.197251 17.4469 0.0320416C14.5664 -0.182731 11.5892 0.687569 9.06309 2.48112C6.55136 4.2642 4.75883 6.76742 4.01093 9.53364C3.21658 9.36076 2.40715 9.12342 1.61132 8.80112L0 12.7586C1.25942 13.2686 2.53066 13.6127 3.75147 13.8387C3.97247 15.2121 4.49656 16.5044 5.31324 17.6482C6.94039 19.9274 9.50464 21.3054 12.5339 21.5287C12.8183 21.5497 13.1035 21.56 13.3891 21.56C15.9962 21.5599 18.6378 20.6937 20.9126 19.0788C22.6386 17.8536 24.0521 16.258 25 14.4647L21.2108 12.4727Z"
                            fill="#363636" />
                    </svg>
                </a>

                <nav className="nav-main">
                    <ul>
                        <li className="nav-main-item_home-link">
                            <NavLink to="/">{fields.home}</NavLink>
                        </li>
                        <li className={this.getNavLinkClass("/tjansten")}>
                            <NavLink to="/tjansten">{fields.tjansten}</NavLink>
                        </li>
                        <li className={this.getNavLinkClass("/utvecklare")}>
                            <NavLink to="/utvecklare">{fields.utvecklare}</NavLink>
                        </li>
                        <li className={this.getNavLinkClass("/cases")}>
                            <NavLink to="/cases">{fields.cases}</NavLink>
                        </li>
                        <li className='nav-main-item__right nav-main-item__help {this.getNavLinkClass("/hjalp")}'>
                            <NavLink to="/hjalp">{fields.help}</NavLink>
                        </li>
                        <li className="nav-main-item__user">
                            <a href="https://pro-prodtest.egreement.com/services/login-proxy/" target="_blank">{fields.login}</a>
                        </li>
                    </ul>
                </nav>
                <a className="user-link" href="https://pro-prodtest.egreement.com/services/login-proxy/" target="_blank">{fields.login}</a>
            </header>
        )
    }
}
export default withRouter(Navbar);