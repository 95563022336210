import React from 'react';
import { slugify } from '../util/url';
import { NavLink } from 'react-router-dom';
import dayjs from 'dayjs';
export default class NyheterList extends React.Component {
    render() {
        var nyheterList = this.props.nyheterList;
        var nyheterToRender = nyheterList.map((nyheter, index) => {
            var date = nyheter.date;
            var formatDate = dayjs(date).format("YYYY-MM-DD")
            return (
                <>
                    <article>
                        <NavLink to={slugify(`/nyheter/${nyheter.title}/`)}>
                            <p className="news-date">{formatDate}</p>
                            <h2>{nyheter.title}</h2>
                            {nyheter.excerpt}
                        </NavLink>
                    </article>
                </>
            )
        })
        return (
            <>
                <main>
                    <h1>Nyheter</h1>
                    {nyheterToRender}
                </main>
            </>
        )
    }
}